import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import CreditScoreHero from '../components/ForCreditScore/CreditScoreHero'
import CreditScoreBenefits from '../components/ForCreditScore/CreditScoreBenefits'
import CreditScoreHowItWorks from '../components/ForCreditScore/CreditScoreHowItWorks'
import CreditScoreGetMore from '../components/ForCreditScore/CreditScoreGetMore'
import DontJustTake from '../components/UI/DontJustTake/index'
import ResourceCenter from '../components/UI/ResourceCenter'
import CreditScoreFAQ from '../components/ForCreditScore/CreditScoreFAQ'
import { parseJson } from '../support'

import BlueCircle from '../assets/images/svg/blue-circle.svg'
import CreditScoreBanner from '../components/ForCreditScore/CreditScoreBanner'
// import { aggregateRating } from '../support/structuredDataConfig/dontJustTakeReview'
// import CreditScoreClub from '../components/ForCreditScore/CreditScoreClub'

const CreditScore = ({ path }) => {
  
  const queriedStructuredDataConfigForCSA = useStaticQuery(graphql`
    query structuredDataConfigForCSQueryA {
      contentfulComponentStructuredDataConfig (
        page: { eq: "credit-score" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredData = parseJson(queriedStructuredDataConfigForCSA.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)
  // structuredData.aggregateRating = aggregateRating

  return (
    <Layout>
      <Seo 
        title="Get Your Free Credit Score Check in Under 60 Seconds"
        description="Unlock your FREE credit score, so you can start making better financial decisions & maybe even get a better deal on a personal loan." 
        path={path}
        structuredData={structuredData}/>
      <CreditScorePageWrapper>
        <div className="bg-circle">
          <BlueCircle />
        </div>
        <CreditScoreHero ai={true} />
      </CreditScorePageWrapper>
      <CreditScoreBenefits />
      <CreditScoreHowItWorks />
      {/* <CreditScoreClub /> */}
      <CreditScoreGetMore background={"var(--base-gray)"}/>
      <ResourceCenter type={'Credit Score'} />
      <CreditScoreFAQ />
      <DontJustTake />
      <CreditScoreBanner />
    </Layout>
  )
}

const CreditScorePageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .bg-circle {
    position: absolute;
    left: auto;
    top: auto;
    right: 30vw;
    bottom: 37%;
    width: 6vw;
    height: auto;
    max-width: 90px;
  }
  @media screen and (max-width: 768px) {
    .bg-circle {
      left: auto;
      top: 40em;
      right: 40vw;
      bottom: auto;
      max-width: 340px;
      svg {
        width: 47vw;
        height: 47vw;
      }
    }
  }
`

export default CreditScore